<template>
  <el-dialog
      :title="stepNum == 0 ? '邀请成员' : '邀请链接'"
      :visible.sync="dialogTableVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="360px"
  >
    <div v-if="stepNum == 0">
      <el-form
          ref="companyFormRef"
          :inline="true"
          :model="companyForm"
          label-position="top"
          label-width="90px"
          :rules="invitePeople"
      >
        <el-form-item prop="inviteTel">
          <el-input
              v-model="companyForm.inviteTel"
              style="width: 320px"
              placeholder="受邀人手机号"
              clearable
              @input="isTrueTel"
          >
            <!--                        <el-select-->
            <!--                            v-model="value"-->
            <!--                            slot="prepend"-->
            <!--                            placeholder="+86"-->
            <!--                            style="width: 90px"-->
            <!--                        >-->
            <!--                            <el-option-->
            <!--                                v-for="item in options"-->
            <!--                                :key="item.value"-->
            <!--                                :label="item.label"-->
            <!--                                :value="item.value"-->
            <!--                            ></el-option>-->
            <!--                        </el-select>-->
            <template
                slot="prepend"
                style="width: 90px"
            >+86
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="notice">
        <div>
          <span style="color: #ff3c3c">注：</span>
          <span>请确认填写的手机号和邀请的同事手机号保持一致</span>
        </div>
        <div style="color: #ff3c3c; margin-left: 24px">
          本次操作代表企业行为，请谨慎操作
        </div>
      </div>
      <div class="button">
        <el-button
            :loading="inviteLoading"
            type="primary"
            plain
            :disabled="nextDisabled"
            @click="onInvite"
        >
          确认
        </el-button>
      </div>
    </div>
    <div v-if="stepNum == 1">
      <div class="font-class">请将链接发送给受邀请人</div>
      <span class="copyLink">
                <el-input v-model="inviteLink" readonly></el-input>
                <el-button
                    type="success"
                    @click="copyLink(inviteLink)"
                    size="small"
                >
                    复制链接
                </el-button>
            </span>
    </div>
  </el-dialog>
</template>
<script>
import {companyInviteStaff} from "@/api";
import {phoneReg} from "@/utils/config";
import {apiCallback} from "../../../../utils";
import {companyInviteStaffByPhone} from "@/api/company";

export default {
  data() {
    return {
      test: "测试信息",
      dialogTableVisible: false,
      companyForm: {
        inviteTel: "",
      },
      inviteLoading:false,
      companyId: -1,
      inviteLink: "",
      stepNum: 0, //0：生成邀请链接 1.复制邀请链接
      invitePeople: {
        inviteTel: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            min: 11,
            max: 11,
            message: "请输入11位手机号码",
            trigger: "blur",
          },
          {
            pattern: phoneReg,
            message: "请输入正确的手机号码",
          },
        ],
      },
      options: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      value: [],
      nextDisabled: true,
    };
  },

  mounted() {
  },
  methods: {
    init(companyId) {
      this.stepNum = 0;
      this.companyId = companyId;
      this.dialogTableVisible = true;
    },
    isTrueTel() {
      this.$refs.companyFormRef.validate((valid) => {
        if (valid) {
          this.nextDisabled = false;
        } else {
          this.nextDisabled = true;
        }
      });
    },
    //直接填手机号邀请
    onInvite(){
      this.inviteLoading=true;
      let data = {};
      data.phone = this.companyForm.inviteTel;
      data.cid = this.companyId;
      companyInviteStaffByPhone(data).then((res)=>{
          if(res.data){
            this.$message.success("邀请成功！");
          }
      }).finally(()=>{
      this.inviteLoading=false;


      })
    },
    //生成邀请链接
    async getInviteLink() {
      var that = this;
      var data = {};
      data.phone = this.companyForm.inviteTel;
      data.cid = this.companyId;
      await companyInviteStaff({
        phone: this.companyForm.inviteTel,
        cid: this.companyId,
      })
          .then((res) => {
            const data = apiCallback(res);
            that.inviteLink =
                window.location.origin +
                "/invite" +
                "?" +
                "key=" +
                encodeURIComponent(data.key) +
                "&channelCode=" +
                encodeURIComponent(data.channelCode);
            this.companyForm.inviteTel = "";
            that.stepNum = 1;
          })
          .catch((err) => console.error(err));
    },
    //复制链接
    copyLink(val) {
      // 复制链接  val 就是你需要复制的链接
      this.$copyText(val).then(
          (e) => {
            this.$message.success("复制成功！");
          },
          (e) => {
            this.$message.error("复制失败！");
          },
      );
    },
    //关闭弹窗
    closeDialog() {
      this.dialogTableVisible = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
.notice {
  font-size: 8px;
}

.button {
  margin-top: 20px;
  display: flex;
}

.font-class {
  padding: 0 20px;
  color: #282828;
}

.copyLink {
  display: flex;
  padding: 10px 20px 20px;
}
</style>
